/* eslint-disable indent */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@pulse-web-ui/button';

import { AdaptiveListWrapper } from '@src/components';
import { numWord } from '@src/utils';

import { ToggleButton } from './risks-accordion.styles';

const MAX_COUNT = 2;

interface Props {
  children: JSX.Element[];
}

export const RisksAccordion = ({ children }: Props) => {
  const { t } = useTranslation(['RISKS_ACCORDION']);
  const remainder = children.length - MAX_COUNT;
  const declensions = [
    t('COMMON:declensions.risk'),
    t('COMMON:declensions.risk-2'),
    t('COMMON:declensions.risk-3'),
  ];
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <AdaptiveListWrapper>
        {isOpen ? children : children.slice(0, MAX_COUNT)}
      </AdaptiveListWrapper>
      {children.length > MAX_COUNT && (
        <ToggleButton>
          <Button variant="text" onClick={() => setIsOpen(!isOpen)}>
            {isOpen
              ? t('buttons.hide')
              : t('buttons.show', {
                  remainder: `${remainder} ${numWord(remainder, declensions)}`,
                })}
          </Button>
        </ToggleButton>
      )}
    </>
  );
};

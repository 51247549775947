import { useContext, useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { FRACTION_TWO } from '@src/constants';
import { StepWizardForm } from '@src/features';
import { useResetMiteSteps } from '@src/hooks';
import {
  AuthActionTypes,
  MiteActionTypes,
  Store,
  UserActionTypes,
} from '@src/store';
import { currencyRuLocaleWithoutFraction } from '@src/utils';

import { useAnalyticsMite } from './hooks';
import { MiteInsured } from './mite-insured';
import { MiteRisks } from './mite-risks';
import { createManyPersons, deletePerson } from './utils';

export const MiteForm: FC = () => {
  const {
    state: {
      stateWizard: { currentStep, isScrollToErrorElement },
      stateMite: {
        choosedPolicy,
        insuranceProductPolicy,
        insurancePrice,
        numberInsuredPersons,
        insuredPersons,
      },
    },
    dispatch,
  } = useContext(Store);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const selectedPolicy = useMemo(
    () =>
      insuranceProductPolicy?.antimitePrograms?.find(
        ({ insuranceProgram }) => insuranceProgram === choosedPolicy
      ),
    [insuranceProductPolicy?.antimitePrograms]
  );

  const policyCoast =
    parseFloat(
      insurancePrice?.premiumAndDeltaPromo || selectedPolicy?.basePrice || ''
    ) * numberInsuredPersons;

  const buttonLabel = t('MITE_FORM:labels.checkoutFor', {
    cost: currencyRuLocaleWithoutFraction(policyCoast, FRACTION_TWO),
  });

  useEffect(() => {
    if (!choosedPolicy) {
      navigate('/mite');
    }
  }, []);

  useAnalyticsMite();

  const goBack = () => {
    if (currentStep === 1) {
      dispatch({
        type: AuthActionTypes.SetConfirmationId,
        payload: undefined,
      });

      dispatch({
        type: AuthActionTypes.SetAuthenticationToken,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetCachedPhoneNumber,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetAuthenticationRefetchUid,
        payload: uuidv4(),
      });

      navigate('/mite');
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    const personsLength = insuredPersons?.length;
    if (personsLength > numberInsuredPersons) {
      const changedPersons = deletePerson(insuredPersons);

      dispatch({
        type: MiteActionTypes.SetInsuredPersons,
        payload: changedPersons,
      });
    } else if (personsLength < numberInsuredPersons) {
      const newPersonsLength = numberInsuredPersons - personsLength;
      const newPersons = createManyPersons(newPersonsLength);

      dispatch({
        type: MiteActionTypes.SetInsuredPersons,
        payload: [...insuredPersons, ...newPersons],
      });
    }
  }, [numberInsuredPersons]);

  return (
    <StepWizardForm
      maxStep={2}
      nextButtonLabel={buttonLabel}
      useResetSteps={useResetMiteSteps} // TODO: реализовать логику для хука useResetMiteSteps
      goBack={goBack}
      scrollToIframeOnChange={!isScrollToErrorElement}
    >
      {currentStep === 1 && <MiteRisks />}
      {currentStep === 2 && <MiteInsured />}
    </StepWizardForm>
  );
};

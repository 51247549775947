import { Swiper } from 'swiper/react';

import { Button } from '@pulse-web-ui/button';
import { media, styled, typography } from '@pulse-web-ui/theme';

export const StyledCardContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  ${typography.medium24}
  margin-bottom: 24px;
  text-align: center;

  ${media.desktop} {
    text-align: left;
  }
`;

export const StyledCardBox = styled.div<{
  active?: boolean;
}>`
  position: relative;
  box-sizing: border-box;
  border: ${({ active, theme }) =>
    `1px solid ${
      active ? theme.colors.accent.action.normal : theme.colors.neutral[30]
    }`};
  border-radius: calc(${({ theme }) => theme.common.radius} * 6);
  padding: 32px 16px 24px;
  width: 100%;

  ${media.desktop} {
    padding: 32px 24px 24px;
  }
`;

export const CardTitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardTotalAmountLabel = styled.div`
  ${typography.regular14}
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: 20px;
  margin-top: 16px;
`;

export const CardTotalAmountValue = styled.div`
  ${typography.medium40}
  margin-top: 4px;
`;

export const StyledCardTitle = styled.div`
  ${typography.medium24}
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const SubTitleBadge = styled.span`
  ${typography.regular12}
  color: ${({ theme }) => theme.colors.text.primary};
  background: ${({ theme }) => theme.colors.surface.background};
  border-radius: ${({ theme }) => theme.common.radius32};
  padding: 2px 8px;
  line-height: 18px;
`;

export const StyledTarifCardList = styled.ul`
  margin: 12px 0 0;
  padding: 0;
  box-sizing: border-box;
`;

export const StyledCardItem = styled.li`
  display: flex;
  justify-content: space-between;
  list-style: none;

  :not(:first-child) {
    margin-top: 8px;
  }
`;

export const StyledCardListKey = styled.span`
  ${typography.regular18}
  line-height: 26px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledCardListValue = styled.span`
  ${typography.medium18}
  display: flex;
  line-height: 26px;
`;

export const StyledButton = styled(Button)`
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 0;
`;

export const StyledButtonBox = styled.div`
  margin-top: 32px;
`;

export const StyledSwiperReact = styled(Swiper)`
  .swiper-pagination-bullet {
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.neutral[30]};
  }

  .swiper-pagination-bullet-active {
    background-color: ${({ theme }) => theme.colors.neutral[100]};
  }

  .swiper-slide {
    height: 557px;
  }
`;

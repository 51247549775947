import { InsurePerson } from '@src/types';

export {
  phoneRegEx,
  petNameRegEx,
  promoEx,
  onlyLetters,
  maxLength,
  onlyCyrillic,
  addressRegEx,
  emailRegEx,
  onlyNumbers,
  spaceAfterNumber,
} from './regex';

export { FeatureFlags } from './feature-flags';

export { ProductKind } from './product-kind';

export { ObjectType } from './object-type';

export { IFrameMessage } from './iframe-message';

export { POST_MESSAGE_TYPE } from './post-message-type';

export { UnicodeEnum } from './unicodes';

export { CurrencyCode } from './currency';

export const INSURANCE_POLICIES = `${window.envUrls.STORAGE_URL}/consents/Soglasie_na_bezakceptnoe_spisanie.pdf`;
export const USER_AGREEMENTS_URL = `${window.envUrls.STORAGE_URL}/consents/Polzovatelskie_soglashenia_ob_ispolzovanii_mobilnogo_prilozhenia_i_saita_OOO_SK_Pulse.pdf`;
export const USER_PERSONAL_DATA_AGREEMENTS = `${window.envUrls.STORAGE_URL}/consents/pd_agreement.pdf`;
export const USER_RECL_AGREEMENTS_URL = `${window.envUrls.STORAGE_URL}/consents/Soglasie_na_poluchenie_reklamno_informacionnih_soobschenii.pdf`;

export const analyticEvents = {
  firstLaunch: 'Auth_First_Launch',
  globalError: 'GlobalError',
  userAgreement: 'Auth_User_Agreement',
  signupStart: 'Auth_Signup_start',
  requestNewOtp: 'Auth_Request_new_otp',
  requestTechSupport: 'Auth_Request_tech_support',
  enterOtpFail: 'Auth_Enter_otp_fail',
  enterOtpSuccess: 'Auth_Enter_otp_success',
  enterSurname: 'Auth_Enter_surname',
  signupCompleted: 'Auth_Signup_completed',
  petsSignupCompleted: 'auth_signup_pet_completed',
  miteSignupCompleted: 'auth_signup_mite_completed',
  sportSignupCompleted: 'auth_signup_sport_completed',
  petsRegistrationCompleted: 'auth_signup_pet_completed',
  miteRegistrationCompleted: 'auth_signup_mite_completed',
  sportRegistrationCompleted: 'auth_signup_sport_completed',
  flatSignupCompleted: 'auth_signup_ifl_completed',
  flatToStep9Pay: 'purchase_flat_to_step_9_pay',
  startRealty: 'Purchase_Start_Realty',
  calculateRealty: 'Purchase_Calculate_Realty',
  regionSelectedRealty: 'Purchase_Region_Selected_Realty',
  typeSelectedRealty: 'Purchase_Type_Selected_Realty',
  toStep2Realty: 'Purchase_To_Step_2_Realty',
  viewOptionRealty: 'Purchase_View_Option_Realty',
  deselectOptionRealty: 'Purchase_Deselect_Option_Realty',
  selectOptionRealty: 'Purchase_Select_Option_Realty',
  toStep3Realty: 'Purchase_To_Step_3_Realty',
  coverageExplanationRealty: 'Purchase_Coverage_Explanation_Realty',
  coverageSumChangeRealty: 'Purchase_Coverage_Sum_Change_Realty',
  toStep4Realty: 'Purchase_To_Step_4_Realty',
  periodSelectRealty: 'Purchase_Period_Select_Realty',
  dateStartRealty: 'Purchase_Date_Start_Realty',
  toStep5Realty: 'Purchase_To_Step_5_Realty',
  addressSelectedRealty: 'Purchase_Address_selected_Realty',
  toStep6Realty: 'Purchase_To_Step_6_Realty',
  toConfirmationRealty: 'Purchase_To_Confirmation_Realty',
  toPurchaseRealty: 'Purchase_To_Purchase_Realty',
  viewAgreementRealty: 'Purchase_View_Agreement_Realty',
  viewDocumentRealty: 'Purchase_View_Document_Realty',
  toPaymentMethodRealty: 'Purchase_To_Payment_Method_Realty',
  toPaymentRealty: 'Purchase_To_Payment_Realty',
  paymentSuccessRealty: 'Purchase_Payment_Success_Realty',
  toPreviousRealty: 'Purchase_To_Previous_Realty',
  startAccident: 'Purchase_Start_Accident',
  calculateAccident: 'Purchase_Calculate_Accident',
  toStep2Accident: 'Purchase_To_Step_2_Accident',
  deselectOptionAccident: 'Purchase_Deselect_Option_Accident',
  selectOptionAccident: 'Purchase_Select_Option_Accident',
  toStep3Accident: 'Purchase_To_Step_3_Accident',
  deselectProtectionAccident: 'Purchase_Deselect_Protection_Accident',
  selectProtectionAccident: 'Purchase_Select_Protection_Accident',
  toStep4Accident: 'Purchase_To_Step_4_Accident',
  coverageSumChangeAccident: 'Purchase_Coverage_Sum_Change_Accident',
  toStep5Accident: 'Purchase_To_Step_5_Accident',
  periodSelectAccident: 'Purchase_Period_Select_Accident',
  dateStartAccident: 'Purchase_Date_Start_Accident',
  toStep6Accident: 'Purchase_To_Step_6_Accident',
  toStep7Accident: 'Purchase_To_Step_7_Accident',
  toConfirmationAccident: 'Purchase_To_Confirmation_Accident',
  toPurchaseAccident: 'Purchase_To_Purchase_Accident',
  viewAgreementAccident: 'Purchase_View_Agreement_Accident',
  viewDocumentAccident: 'Purchase_View_Document_Accident',
  toPaymentMethodAccident: 'Purchase_To_Payment_Method_Accident',
  toPaymentAccident: 'Purchase_To_Payment_Accident',
  paymentSuccessAccident: 'Purchase_Payment_Success_Accident',
  nsPaymentSuccess: 'Purchase_NS_Payment_Success',
  toPreviousAccident: 'Purchase_To_Previous_Accident',
  iflStep1Address: 'Purchase_IFL_To_Step_1_Address',
  iflStep2Risk: 'Purchase_IFL_To_Step_2_Risk',
  iflStep3AddRisk: 'Purchase_IFL_To_Step_3_AddRisk',
  iflStep4Сoverage: 'Purchase_IFL_To_Step_4_Сoverage',
  iflStep5Period: 'Purchase_IFL_To_Step_5_Period',
  iflStep6Yourdata: 'Purchase_IFL_To_Step_6_Yourdata',
  iflStep7Confirm: 'Purchase_IFL_To_Step_7_Confirm',
  iflStep8Docs: 'Purchase_IFL_To_Step_8_Docs',
  iflStep9Pay: 'Purchase_IFL_To_Step_9_Pay',
  iflPaymentSuccess: 'Purchase_IFL_Payment_Success',
  iflPaymentDecline: 'Purchase_IFL_Payment_Decline',
  iflFlatStep1Address: 'Purchase_Flat_To_Step_1_Address',
  iflFlatStep2Risk: 'Purchase_Flat_To_Step_2_Risk',
  iflFlatStep3Risk: 'Purchase_Flat_To_Step_3_AddRisk',
  iflFlatStep4Сoverage: 'Purchase_Flat_To_Step_4_Сoverage',
  iflFlatStep5Period: 'Purchase_Flat_To_Step_5_Period',
  iflFlatStep6Yourdata: 'Purchase_Flat_To_Step_6_Yourdata',
  iflFlatStep7Confirm: 'Purchase_Flat_To_Step_7_Confirm',
  iflFlatStep8Docs: 'Purchase_Flat_To_Step_8_Docs',
  iflFlatStep9Pay: 'Purchase_Flat_To_Step_9_Pay',
  iflFlatPaymentSuccess: 'Purchase_Flat_Payment_Success',
  iflFlatPaymentDecline: 'Purchase_Flat_Payment_Decline',
  iflHouseStep1Address: 'Purchase_House_To_Step_1_Address',

  // Регистрация/Авторизация Квартиры
  authFlatRegistrationStart: 'Auth_Flat_Registration_Start',
  authFlatRegistrationCompleted: 'Auth_Flat_Registration_Completed',
  authFlatSignupCompleted: 'Auth_Flat_Signup_Completed',

  // Регистрация/Авторизация Дома
  authHouseRegistrationStart: 'Auth_House_Registration_Start',
  authHouseRegistrationCompleted: 'Auth_House_Registration_Completed',
  authHouseSignupCompleted: 'Auth_House_Signup_Completed',
  authHouseRegistrationStartPreset: 'Auth_House_Registration_Start_p',
  authHouseRegistrationCompletedPreset: 'Auth_House_Registration_Completed_p',
  authHouseSignupCompletedPreset: 'Auth_House_Signup_Completed_p',

  // Регистрация/Авторизация НС
  authNSRegistrationStart: 'Auth_NS_Registration_Start',
  authNSRegistrationCompleted: 'Auth_NS_Registration_Completed',
  authNSSignupCompleted: 'Auth_NS_Signup_Completed',

  // Регистрация/Авторизация Питомцев
  authPetRegistrationStart: 'Auth_Pet_Registration_Start',
  authPetRegistrationCompleted: 'Auth_Pet_Registration_Completed',
  authPetSignupCompleted: 'Auth_Pet_Signup_Completed',
  authPetRegistrationStartPreset: 'Auth_Pet_Registration_Start_p',
  authPetRegistrationCompletedPreset: 'Auth_Pet_Registration_Completed_p',
  authPetSignupCompletedPreset: 'Auth_Pet_Signup_Completed_p',

  // Регистрация/Авторизация Спорт НС
  authSportRegistrationStart: 'Auth_Sport_Registration_Start',
  authSportRegistrationCompleted: 'Auth_Sport_Registration_Completed',
  authSportSignupCompleted: 'Auth_Sport_Signup_Completed',

  // Регистрация/Авторизация Антиклещ
  authMiteRegistrationStart: 'Auth_Mite_Registration_Start',
  authMiteRegistrationCompleted: 'Auth_Mite_Registration_Completed',
  authMiteSignupCompleted: 'Auth_Mite_Signup_Completed',

  // Пресеты Дом
  iflHousePresetStep1Address: 'Purchase_House_To_Step_1_Address_p',
  iflHousePresetStep2Period: 'Purchase_House_To_Step_2_Period_p',
  iflHousePresetToConfig: 'Purchase_House_To_Config_p',
  iflHousePresetChangeRisk: 'Purchase_House_Change_Risk_p',
  iflHousePresetConfigExit: 'Purchase_House_Config_Exit_p',
  iflHousePresetRisk: 'Purchase_House_To_Risk_p',
  iflHousePresetAddRisk: 'Purchase_House_To_AddRisk_p',
  iflHousePresetChangeAddRisk: 'Purchase_House_Change_AddRisk_p',
  iflHousePresetToCoverage: 'Purchase_House_To_Coverage_p',
  iflHousePresetChangeCoverage: 'Purchase_House_Change_Coverage_p',
  iflHousePresetStep3Confirm: 'Purchase_House_To_Step_3_Confirm_p',
  iflHousePresetStep4Docs: 'Purchase_House_To_Step_4_Docs_p',
  iflHousePresetStep5Pay: 'Purchase_House_To_Step_5_Pay_p',
  iflHousePresetPaymentSuccess: 'Purchase_House_Payment_Success_p',
  iflHousePresetPaymentDecline: 'Purchase_House_Payment_Decline_p',

  // Пресеты Авторизации
  webPresetStart: 'Purchase_Web_Start_p',
  signupPresetPhone: 'Auth_Signup_phone_p',
  firstPresetLaunch: 'Auth_First_Launch_p',
  signupPresetStart: 'Auth_Signup_start_p',
  requestPresetNewOtp: 'Auth_Request_new_otp_p',
  enterPresetOtpFail: 'Auth_Enter_otp_fail_p',
  enterPresetOtpSuccess: 'Auth_Enter_otp_success_p',
  enterPresetSurname: 'Auth_Enter_surname_p',
  enterPresetSurnameFail: 'Auth_Enter_surname_fail_p',
  signupPresetCompleted: 'Auth_Signup_completed_p',

  iflHouseMaterials: 'Purchase_House_To_Step_Buildingtype',
  iflHouseStep2Risk: 'Purchase_House_To_Step_2_Risk',
  iflHouseStep3Risk: 'Purchase_House_To_Step_3_AddRisk',
  iflHouseStep4Сoverage: 'Purchase_House_To_Step_4_Сoverage',
  iflHouseStep5Period: 'Purchase_House_To_Step_5_Period',
  iflHouseStep6Yourdata: 'Purchase_House_To_Step_6_Yourdata',
  iflHouseStep7Confirm: 'Purchase_House_To_Step_7_Confirm',
  iflHouseStep8Docs: 'Purchase_House_To_Step_8_Docs',
  iflHouseStep9Pay: 'Purchase_House_To_Step_9_Pay',
  iflHousePaymentSuccess: 'Purchase_House_Payment_Success',
  iflHousePaymentDecline: 'Purchase_House_Payment_Decline',
  authVIPapp: 'Auth_VIP_app',
  petToStepPetType: 'Purchase_Pet_To_Step_PetType',
  petToStepRisk: 'Purchase_Pet_To_Step_Risk',
  petToStepAddRisk: 'Purchase_Pet_To_Step_AddRisk',
  petToStepCoverage: 'Purchase_Pet_To_Step_Coverage',
  petToStepPeriod: 'Purchase_Pet_To_Step_Period',
  petToStepYourData: 'Purchase_Pet_To_Step_YourData',
  petToStepConfirm: 'Purchase_Pet_To_Step_Confirm',
  petToStepDocs: 'Purchase_Pet_To_Step_Docs',
  petToStepPay: 'Purchase_Pet_To_Step_Pay',
  petToStepPayAd: 'purchase_pet_to_step_pay',
  petPaymentSuccess: 'Purchase_Pet_Payment_Success',
  petPaymentDecline: 'Purchase_Pet_Payment_Decline',
  webStart: 'Purchase_Web_Start',
  webHasSubsSelected: 'Purchase_Web_Has_Subs_Selected',
  webProducts: 'Purchase_Web_Products',
  webProductsSelected: 'Purchase_Web_Products_Selected',
  signupPhone: 'Auth_Signup_phone',
  enterSurnameFail: 'Auth_Enter_surname_fail',
  sportToStepCount: 'Purchase_Sport_To_Step_Count',
  sportToStepType: 'Purchase_Sport_To_Step_Type',
  sportToStepCoverage: 'Purchase_Sport_To_Step_Coverage',
  sportToStepData: 'Purchase_Sport_To_Step_Data',
  sportToStepConfirm: 'Purchase_Sport_To_Step_Confirm',
  sportToStepDocs: 'Purchase_Sport_To_Step_Docs',
  sportToStepPay: 'Purchase_Sport_To_Step_Pay',
  sportToStepPayAd: 'purchase_sport_to_step_pay',
  sportPaymentSuccess: 'Purchase_Sport_Payment_Success',
  sportPaymentDecline: 'Purchase_Sport_Payment_Decline',
  completeRegistration: 'complete_registration',
  sportCompleteRegistration: 'sport_complete_registration',
  antimiteCompleteRegistration: 'antimite_complete_registration',
  flatCompleteRegistration: 'flat_complete_registration',
  petCompleteRegistration: 'pets_complete_registration',
  completeLogin: 'complete_login',
  antimiteCompleteLogin: 'antimite_complete_login',
  sportCompleteLogin: 'sport_complete_login',
  flatCompleteLogin: 'flat_complete_login',
  petCompleteLogin: 'pets_complete_login',
  miteSubmitApp: 'antimite_submit_application',
  miteToStepPolicy: 'Purchase_Mite_To_Step_Policy',
  miteToStepPrice: 'Purchase_Mite_To_Step_Price',
  miteToStepData: 'Purchase_Mite_To_Step_Data',
  miteToStepConfirm: 'Purchase_Mite_To_Step_Confirm',
  miteToStepDocs: 'Purchase_Mite_To_Step_Docs',
  miteToStepPay: 'Purchase_Mite_To_Step_Pay',
  miteToStepPayAd: 'purchase_mite_to_step_pay',
  mitePaymentSuccess: 'Purchase_Mite_Payment_Success',
  mitePaymentDecline: 'Purchase_Mite_Payment_Decline',
  nsPaymentDecline: 'Purchase_NS_Payment_Decline',
  // PETS PRESET
  petToStepPetTypePreset: 'Purchase_Pet_To_Step_PetType_p',
  petToStepConfirmPreset: 'Purchase_Pet_To_Step_Confirm_p',
  petToConfigPreset: 'Purchase_Pet_To_Config_p',
  petToConfigExitPreset: 'Purchase_Pet_Config_Exit_p',
  petChangeRiskPreset: 'Purchase_Pet_Change_Risk_p',
  petChangeAddRiskPreset: 'Purchase_Pet_Change_AddRisk_p',
  petChangeCoveragePreset: 'Purchase_Pet_Change_Coverage_p',
  petToStepAddRiskPreset: 'Purchase_Pet_To_Step_AddRisk_p',
  petToStepRiskPreset: 'Purchase_Pet_To_Step_Risk_p',
  petToStepCoveragekPreset: 'Purchase_Pet_To_Step_Coverage_p',
  petToStepPayPreset: 'Purchase_Pet_To_Step_Pay_p',
  petPaymentSuccessPreset: 'Purchase_Pet_Payment_Success_p',
  petPaymentDeclinePreset: 'Purchase_Pet_Payment_Decline_p',
  petToStepPeriodPreset: 'Purchase_Pet_To_Step_Period_p',
  petToStepDocsPreset: 'Purchase_Pet_To_Step_Docs_p',
  // FLAT PRESET
  iflFlatPresetConfigExit: 'Purchase_Flat_Config_Exit_p',
  iflFlatPresetRisk: 'Purchase_Flat_To_Risk_p',
  iflFlatPresetAddRisk: 'Purchase_Flat_To_AddRisk_p',
  iflFlatPresetToCoverage: 'Purchase_Flat_To_Coverage_p',
  iflFlatPresetStep1Address: 'Purchase_Flat_To_Step_1_Address_p',
  iflFlatPresetStep2Period: 'Purchase_Flat_To_Step_2_Period_p',
  iflFlatPresetStep3Confirm: 'Purchase_Flat_To_Step_3_Confirm_p',
  iflFlatPresetStep4Docs: 'Purchase_Flat_To_Step_4_Docs_p',
  iflFlatPresetStep5Pay: 'Purchase_Flat_To_Step_5_Pay_p',
  iflFlatPresetToConfig: 'Purchase_Flat_To_Config_p',
  iflFlatPresetChangeRisk: 'Purchase_Flat_Change_Risk_p',
  iflFlatPresetChangeAddRisk: 'Purchase_Flat_Change_AddRisk_p',
  iflFlatPresetChangeCoverage: 'Purchase_Flat_Change_Coverage_p',
  iflFlatPresetPaymentSuccess: 'Purchase_Flat_Payment_Success_p',
  iflFlatPresetPaymentDecline: 'Purchase_Flat_Payment_Decline_p',

  somethingWentWrong: 'SmthWentWrong',

  cpaScaleoConvStatus: 'approved',

  // Телевет Плюс
  petTelPetTypeOpen: 'Purchase_PetTel_PetType_Open',
  petTelToOpenRisk: 'Purchase_PetTel_To_Open_Risk',
  petTelConfirmOpen: 'Purchase_PetTel_Confirm_Open',
  petTelDocsOpen: 'Purchase_PetTel_Docs_Open',
  petTelPayOpen: 'Purchase_PetTel_Pay_Open',
  petTelPaySuccess: 'Purchase_PetTel_Payment_Success',
  petTelPayDecline: 'Purchase_PetTel_Payment_Decline',
  petTelRegStart: 'Auth_PetTel_Registration_Start',
  petTelRegCompleted: 'Auth_PetTel_Registration_Completed',
  petTelSignupCompleted: 'Auth_PetTel_Signup_Completed',
};

export const salesNinjaEvents = {
  purchaseSportPaymentSuccess: '41447acb-5733-4769-ac51-bc649d9c1448',
  authSportSignupCompleted: 'ea0cc145-1dd8-478b-926a-beda85283563',
  purchaseMitePaymentSuccess: '763887ee-cb2b-4a9e-a1b5-97928dd4a383',
  authMiteSignupCompleted: '3b337b61-0707-49a7-bc39-58e0acb7e9a6',
  purchaseNSPaymentSuccess: '385821cc-f99e-4215-ad2c-cf0682fde161',
  authNSSignupCompleted: '18cd745f-2659-4f55-8800-32c64dfe61d4',
  purchasePetPaymentSuccess: 'bab36c73-b03a-45c4-a127-6ff58c4dfb48',
  authPetSignupCompleted: 'ea1712ce-1113-4c3b-905f-f22d66e649a5',
  purchaseFlatPaymentSuccess: 'd80ffcb6-846f-4667-8a01-0f53391d777a',
  authFlatSignupCompleted: '5f894440-9b49-4135-b529-78bf0773bc2f',
  purchaseHousePaymentSuccess: '1400f108-16eb-4357-99af-a5b706c0312a',
  authHouseSignupCompleted: 'e38a1db9-cc3c-4816-8e57-6400e63cf71c',
};

export const adriverEvents = {
  authPetRegistrationCompleted: 'auth_pet_registration_complete',
  authSportRegistrationCompleted: 'auth_sport_registration_completed',
  authFlatRegistrationCompleted: 'auth_flat_registration_completed',
  authHouseRegistrationCompleted: 'auth_house_registration_completed',
  authNSRegistrationCompleted: 'auth_ns_registration_completed',
  authMiteRegistrationCompleted: 'auth_mite_registration_completed',
};

export const durationDescription = {
  P1M: 'month',
  P3M: 'quarter',
  P1Y: 'year',
};

export const insurancePersonDefaultData: InsurePerson = {
  isDisabledForm: false,
  isMe: false,
  ageMin: 1,
  birthDate: '',
  firstName: '',
  lastName: '',
  middleName: '',
};

export const iflRoute = '/'; // TODO: корень должен измениться на ИФЛ
export const iflHouseRoute = '/house';
export const iflFlatRoute = '/apartment';
export const nsRoute = '/ns';
export const petsRoute = '/pets';
export const sportNsRoute = '/sport-ns';
export const miteRoute = '/mite';
export const miteStepsRoute = '/mite-steps';
export const televetPlusRoute = '/pets-televet-plus';

export const insuranceProductsCode = {
  house: '08.16',
  flat: '08.15',
  accident: '02.09',
  pets: '00.04',
  sport: '02.13',
  mite: '00.09',
  ns: '02.09',
  petsTelevetPlus: '00.10',
};

export { ProductVersion } from './product-version';
export { SubscriptionType } from './subscriptioin-type';

export const PRODUCT_VERSION_ONE = '1';
export const PRODUCT_VERSION_TWO = '2';

export { ClientCategory } from './client-category';
export * from './routes';

export enum TextAlignDirection {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum HorizontalAlignDirection {
  START = 'flex-start',
  CENTER = 'center',
  END = 'flex-end',
}

export enum Product {
  HOUSE = 'house',
  APARTMENT = 'apartment',
  NS = 'ns',
  PETS = 'pets',
  PETS_TELEVET_PLUS = 'pets-televet-plus',
  SPORT = 'sport-ns',
  MITE = 'mite',
  TRAVEL = 'travel',
}

export const nonSubscriptionProducts: string[] = [Product.SPORT];
export const vipClientAvailableProductsList: string[] = [
  Product.SPORT,
  Product.MITE,
  Product.PETS,
  Product.HOUSE,
];

export const DEFAULT_FIAS_ID = '00000000-0000-0000-0000-000000000000';

export enum UseQueryStatus {
  ERROR = 'error',
  SUCCESS = 'success',
  IDLE = 'idle',
  LOADING = 'loading',
}

export const DONT_RECOGNIZE_YOU_TITLE = 'AUTH:labels.weDontRecognizeYou';

export enum ClientDocumentType {
  MEMO = 'Memo',
  RULES = 'Rules',
  PROGRAM = 'Program',
  CONTRACT = 'Contract',
  KEY_INFO_DOCUMENT = 'KeyInfoDocument',
}

export const DEFAULT_INSURANCE_SUM = 200000;

export const FRACTION_TWO = {
  maxFraction: 2,
  minFraction: 2,
};

export const TIME_ZONE_MOSCOW = 'Europe/Moscow';

export const MOSCOW_TIME_ZONE_OFFSET = -180;

export { HEADER_HEIGHT } from './header-height';

export { ApiEndpoints, MethodApi } from './request-data';

export { RoutePath } from './router';

export { FlatSteps, HouseSteps } from './steps';

export { PaymentStatus } from './payment-status';

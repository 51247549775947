import type { InsuredPerson } from '../mite.types';
import { createOnePerson } from './create-one-person';

export const createManyPersons = (count?: number) => {
  const persons: InsuredPerson[] = [];

  if (!count) {
    return persons;
  }

  for (let i = 0; i < count; i++) {
    persons.push(createOnePerson());
  }

  return persons;
};

/* eslint-disable indent */
import {
  FC,
  ReactElement,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Pagination, Swiper } from 'swiper';
import 'swiper/components/pagination/pagination.min.css';
import { SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import { Container, Skeleton } from '@src/components';
import { AnalyticsType, sendAnalyticEvent } from '@src/components/web-analytic';
import { analyticEvents, insuranceProductsCode } from '@src/constants';
import { MitePolicyChoose } from '@src/constants/mite-choose';
import { useHandlePressKey, useIsDesktop, useRequest } from '@src/hooks';
import { BaseLayout } from '@src/layouts';
import { MiteActionTypes, Store, WizardActionTypes } from '@src/store';
import { KeyCode } from '@src/types';
import {
  addTestAttribute,
  convertDateFormatInTimeZone,
  scrollToIframe,
} from '@src/utils';

import { GlobalErrorInfo } from '../global-error-info';
import { useMiteChoose } from './hooks/useMiteChoose';
import {
  CardTitleBox,
  CardTotalAmountLabel,
  CardTotalAmountValue,
  StyledButton,
  StyledButtonBox,
  StyledCardBox,
  StyledCardContainer,
  StyledCardItem,
  StyledCardListKey,
  StyledCardListValue,
  StyledCardTitle,
  StyledSwiperReact,
  StyledTarifCardList,
  SubTitleBadge,
  Title,
} from './mite.styles';

interface CardType {
  key: string | JSX.Element;
  value: JSX.Element | string;
  isLast?: boolean;
}

Swiper.use([Pagination]);
const DEFAULT_IDX = 0;

export const MitePolicyCards: FC = memo((): ReactElement => {
  const {
    state: {
      stateMite: { insuranceProductPolicy, choosedPolicy },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);
  const { t } = useTranslation();
  const [isPolicyClicked, setIsPolicyClicked] = useState(false);
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const MITE_CHOOSE = useMiteChoose();

  const { isLoading, error, res, refetch } = useRequest(
    'mitePolicyChoose',
    'get',
    `/v4/references/insurance-programs/${insuranceProductsCode.mite}`,
    {},
    [],
    false,
    authTokens?.authorization?.accessToken
  );

  const storeInsuranceProduct = useCallback(
    (data) => {
      dispatch({
        type: MiteActionTypes.SetInsuranceProductPolicy,
        payload: data || undefined,
      });
    },
    [res]
  );

  const handleKeyPressEnter = () => {
    dispatch({
      type: MiteActionTypes.SetChoosedPolicy,
      payload: choosedPolicy ? choosedPolicy : MitePolicyChoose.STANDARD,
    });
    setIsPolicyClicked(true);
    sendAnalyticEvent(
      analyticEvents.miteSubmitApp,
      {},
      AnalyticsType.myTracker
    );
  };

  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter);

  const handlePolicyChooseClick = (
    insuranceProgram: string,
    minNumObjects: number,
    startDate: string
  ) => {
    dispatch({
      type: WizardActionTypes.SetCurrentStep,
      payload: 1,
    });

    dispatch({
      type: MiteActionTypes.SetStartDate,
      payload: convertDateFormatInTimeZone(new Date(startDate)),
    });

    dispatch({
      type: MiteActionTypes.SetChoosedPolicy,
      payload: insuranceProgram,
    });

    dispatch({
      type: MiteActionTypes.SetNumberInsuredPersons,
      payload: minNumObjects,
    });

    setIsPolicyClicked(true);
    sendAnalyticEvent(
      analyticEvents.miteSubmitApp,
      {},
      AnalyticsType.myTracker
    );
    sendAnalyticEvent(analyticEvents.miteToStepPrice);
  };

  useEffect(() => {
    if (!isLoading && res) {
      storeInsuranceProduct(res);
    }
  }, [isLoading, res]);

  useEffect(() => {
    isPolicyClicked && navigate('/mite-steps');
  }, [isPolicyClicked]);

  const filteredPrograms = insuranceProductPolicy?.antimitePrograms?.filter(
    (program) =>
      program.insuranceProgram === MitePolicyChoose.STANDARD ||
      program.insuranceProgram === MitePolicyChoose.EXTENDED
  );

  const activeCardIdx = useMemo(
    () =>
      !filteredPrograms || !choosedPolicy
        ? DEFAULT_IDX
        : filteredPrograms?.findIndex(
            ({ insuranceProgram }) => choosedPolicy === insuranceProgram
          ),
    [filteredPrograms, choosedPolicy]
  );

  if (isLoading) return <Skeleton />;

  if (error) {
    return <GlobalErrorInfo retryHandler={refetch} />;
  }

  const cardMenuData = (card: Array<CardType>) => (
    <StyledTarifCardList>
      {card.map(({ key, value }, idx: number) => (
        <StyledCardItem key={idx}>
          <StyledCardListKey>{key}</StyledCardListKey>
          <StyledCardListValue>{value}</StyledCardListValue>
        </StyledCardItem>
      ))}
    </StyledTarifCardList>
  );

  const renderCards = (isSwiper?: boolean) =>
    filteredPrograms?.map((card, idx) => {
      const {
        name,
        maxNumObjects,
        minNumObjects,
        basePrice,
        insuranceProgram,
        showByDefault,
        startDate,
      } = card;
      const isActive = choosedPolicy
        ? choosedPolicy === insuranceProgram
        : showByDefault;
      const currentProgram =
        MITE_CHOOSE[insuranceProgram as keyof typeof MITE_CHOOSE];

      const cardContent = (
        <>
          <CardTitleBox>
            <StyledCardTitle>{name}</StyledCardTitle>
            <SubTitleBadge>
              {maxNumObjects > 1
                ? t('MITE_FORM:labels.maxPersons', {
                    maxCount: maxNumObjects,
                  })
                : t('MITE_FORM:subLabels.person')}
            </SubTitleBadge>
          </CardTitleBox>
          <CardTotalAmountLabel>
            {t('MITE_FORM:labels.amountForTreatment')}
          </CardTotalAmountLabel>
          <CardTotalAmountValue>
            {t('COMMON:price.million', {
              price: currentProgram.coverageAmount,
            })}
          </CardTotalAmountValue>
          {cardMenuData(currentProgram.cardData)}
          <StyledButtonBox>
            <StyledButton
              onClick={() => {
                scrollToIframe();
                handlePolicyChooseClick(
                  insuranceProgram,
                  minNumObjects,
                  startDate
                );
              }}
              buttonSize="s"
              {...addTestAttribute(`mite-policy-button-${insuranceProgram}`)}
            >
              {t('MITE_FORM:buttonLabel.orderFrom', {
                price: parseFloat(basePrice),
              })}
            </StyledButton>
          </StyledButtonBox>
        </>
      );

      return isSwiper ? (
        <SwiperSlide key={idx}>
          <StyledCardBox active={isActive}>{cardContent}</StyledCardBox>
        </SwiperSlide>
      ) : (
        <StyledCardBox active={isActive} key={idx}>
          {cardContent}
        </StyledCardBox>
      );
    });

  return (
    <BaseLayout minHeight={500}>
      <Container>
        <Title>{t('COMMON:labels.choosePolicy')}</Title>
        {isDesktop ? (
          <StyledCardContainer>{renderCards()}</StyledCardContainer>
        ) : (
          <StyledSwiperReact
            initialSlide={activeCardIdx}
            breakpoints={{
              320: {
                slidesPerView: 1.1,
                centeredSlides: true,
                spaceBetween: 16,
              },
            }}
            pagination={{
              clickable: true,
            }}
          >
            <StyledCardContainer>{renderCards(true)}</StyledCardContainer>
          </StyledSwiperReact>
        )}
      </Container>
    </BaseLayout>
  );
});

import { FC, useContext, useEffect } from 'react';

import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents, miteRoute } from '@src/constants';
import { MitePolicyCards } from '@src/features/mite-policy/mite-policy';
import { AuthActionTypes, Store } from '@src/store';

export const MitePolicyChoice: FC = () => {
  const { dispatch } = useContext(Store);

  useEffect(() => {
    localStorage.removeItem('successText');
    localStorage.removeItem('startDate');

    dispatch({
      type: AuthActionTypes.SetAuthorizeRefRoute,
      payload: miteRoute,
    });

    sendAnalyticEvent(analyticEvents.miteToStepPolicy);
  }, []);

  return <MitePolicyCards />;
};

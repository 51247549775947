import { Trans, useTranslation } from 'react-i18next';

import { Close, SuccessCheck } from '@pulse-web-ui/icons';
import { useTheme } from '@pulse-web-ui/theme';

import { MitePolicyChoose } from '@src/constants/mite-choose';

export const useMiteChoose = () => {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const defaultCardData = [
    {
      key: t('MITE_FORM:labels.outpatientCare'),
      value: <SuccessCheck width={24} color={theme.colors.icon.success} />,
    },
    {
      key: t('MITE_FORM:labels.inpatientTreatment'),
      value: <SuccessCheck width={24} color={theme.colors.icon.success} />,
    },
    {
      key: <Trans>{t('MITE_FORM:labels.medicationCosts')}</Trans>,
      value: t('COMMON:price.toRubles', { price: '15 000' }),
    },
    {
      key: t('MITE_FORM:labels.rehabilitation'),
      value: <SuccessCheck width={24} color={theme.colors.icon.success} />,
    },
  ];

  return {
    [MitePolicyChoose.STANDARD]: {
      coverageAmount: '1,5',
      cardData: [
        ...defaultCardData,
        {
          key: t('MITE_FORM:labels.payments'),
          value: <Close width={24} color={theme.colors.icon.error} />,
        },
      ],
    },
    [MitePolicyChoose.EXTENDED]: {
      coverageAmount: '3',
      cardData: [
        ...defaultCardData,
        {
          key: t('MITE_FORM:labels.payments'),
          value: t('COMMON:price.toRubles', { price: '500 000' }),
        },
      ],
    },
  };
};

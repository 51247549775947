import { v4 as uuid } from 'uuid';

import type { InsuredPerson } from '../mite.types';

export const createOnePerson = () => {
  return {
    id: uuid(),
    isDisabledForm: false,
    isMe: false,
    ageMin: 1,
    birthDate: '',
    firstName: '',
    lastName: '',
    middleName: '',
  } as InsuredPerson;
};

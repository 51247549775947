/* eslint-disable indent */
import { media, mixins, styled, typography } from '@pulse-web-ui/theme';

import { CustomButtonProps } from '@src/components/form-elements/form-elements.types';

export const StyledVisibleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledDeleteButton = styled.div`
  ${typography.regular18}
  color:  ${({ theme }) => theme.colors.text.error};
  margin-right: 12px;
  cursor: pointer;
`;

export const InsurancePersonFormHead = styled.div`
  ${mixins.flex({
    alignItems: 'center',
    justifyContent: 'space-between',
    wrap: false,
    gap: 12,
  })}
  min-height: 28px;
  margin-bottom: 16px;
`;

export const InsurancePersonGroup = styled.div`
  width: 100%;
`;

export const InsurancePersonFormContainer = styled.div`
  ${mixins.flex({
    horizontal: false,
    wrap: false,
    horizontalAlignment: 'flex-start',
    gap: 32,
  })}
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const InsurancePersonFormTitleBox = styled.div`
  ${mixins.flex({
    alignItems: 'center',
    wrap: false,
    gap: 8,
  })}
`;

export const InsurancePersonFormTitle = styled.div`
  ${typography.medium18}
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const InsurancePersonFormSubTitle = styled.div`
  ${typography.regular14}
  color: ${({ theme }) => theme.colors.text.secondary};
  padding-top: 2px;
`;

export const InsurancePersonFormNumber = styled.div<{
  choosedPolicy?: string;
}>`
  ${typography.pulsSubhead4.regular}
  ${mixins.flex({
    alignItems: 'center',
    justifyContent: 'center',
  })}
  // TODO: RGS_ONLINE_THEME. Для партнеров color: theme.colors.text.inverse
  color:  ${({ theme }) => theme.colors['on-accent'].primary};
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.colors.surface.brand};

  border-radius: 50%;

  ${media.desktop} {
    width: 24px;
    height: 24px;
  }
`;

export const InsurancePersonFormButton = styled.button<CustomButtonProps>`
  ${typography.regular14}
  background: ${({ active, theme }) =>
    active ? theme.colors.accent.action.clarified : 'none'};
  padding: 3px 7px;
  cursor: pointer;
  user-select: none;
  outline: none;
  border: 1px solid
    ${({ active, theme }) =>
      active ? theme.colors.accent.action.clarified : theme.colors.neutral[30]};
  border-radius: ${({ theme }) => theme.common.radius32};
  color: ${({ active, theme }) =>
    active ? theme.colors['on-accent'].primary : theme.colors.text.primary};
  line-height: 20px;
  white-space: nowrap;
`;

export const StyledButton = styled.div``;

export const InsurancePersonFormLabel = styled.div`
  ${typography.medium18}

  ${media.desktop} {
    ${typography.medium20}
  }
`;

import { useMemo } from 'react';

import { ClientDocumentType } from '@src/constants';
import type { DocumentType } from '@src/types';

export const useSortedMiteDocuments = (documents: DocumentType[]) => {
  const sortOrder: ClientDocumentType[] = [
    ClientDocumentType.CONTRACT,
    ClientDocumentType.MEMO,
    ClientDocumentType.PROGRAM,
    ClientDocumentType.KEY_INFO_DOCUMENT,
  ];

  const orderMap: { [key in ClientDocumentType]: number } = useMemo(() => {
    return sortOrder.reduce((acc, type, index) => {
      acc[type] = index;
      return acc;
    }, {} as { [key in ClientDocumentType]: number });
  }, [sortOrder]);

  const sortedDocuments = useMemo(() => {
    return [...documents].sort((a, b) => {
      const aIndex =
        orderMap[a.clientDocumentType as ClientDocumentType] ?? Infinity;
      const bIndex =
        orderMap[b.clientDocumentType as ClientDocumentType] ?? Infinity;
      return aIndex - bIndex;
    });
  }, [documents, orderMap]);

  return sortedDocuments;
};

import { HTMLProps } from 'react';

import { DaDataFioSuggestion } from '@pulse-web-ui/dadata';

export enum FilterParts {
  NAME = 'NAME',
  PATRONYMIC = 'PATRONYMIC',
  SURNAME = 'SURNAME',
}

export type DadataInputProps = HTMLProps<HTMLInputElement> & {
  error?: boolean;
  noMargin?: boolean;
};

export type StyledDadataFioSuggestionsProps = {
  onChange: (value: undefined | DaDataFioSuggestion) => void;
  value?: DaDataFioSuggestion;
  isPossibleOverflow?: boolean;
  defaultQuery?: string;
  filterParts?: FilterParts[];
  delay?: number;
  minChars?: number;
  uid?: string;
  inputProps?: DadataInputProps;
  error?: boolean;
};

import type { InsuredPerson } from '../mite.types';

const getEmptyFields = (persons: InsuredPerson[], searchAll = false) => {
  if (searchAll) {
    return persons.filter(
      (person) =>
        !person.firstName &&
        !person.lastName &&
        !person.middleName &&
        !person.birthDate
    );
  }

  return persons.filter(
    (person) =>
      !person.firstName ||
      !person.lastName ||
      !person.middleName ||
      !person.birthDate
  );
};

const removePerson = (persons: InsuredPerson[], id: string) => {
  return persons.filter((person) => person.id !== id);
};

export const deletePerson = (persons: InsuredPerson[]) => {
  let filteredPersons = [...persons];
  const emptyFieldsAll = getEmptyFields(filteredPersons, true);
  const emptyFieldsAny = getEmptyFields(filteredPersons);
  const emptyFieldsAllLength = emptyFieldsAll.length;
  const emptyFieldsAnyLength = emptyFieldsAny.length;

  if (emptyFieldsAllLength) {
    filteredPersons = removePerson(
      filteredPersons,
      emptyFieldsAll[emptyFieldsAllLength - 1].id
    );
  } else if (emptyFieldsAnyLength) {
    filteredPersons = removePerson(
      filteredPersons,
      emptyFieldsAny[emptyFieldsAnyLength - 1].id
    );
  } else {
    filteredPersons = filteredPersons.slice(0, -1);
  }

  return filteredPersons;
};

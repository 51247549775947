import { useTranslation } from 'react-i18next';

import { Spinner } from '@pulse-web-ui/spinner';

import { FRACTION_TWO, UnicodeEnum } from '@src/constants';
import { currencyRuLocaleWithoutFraction } from '@src/utils';

import {
  Cost,
  CostPerPerson,
  InsurancePriceCardContainer,
  InsurancePriceCardTitle,
  OldCost,
} from './insurance-mite-price-card.styles';

interface Props {
  title: string;
  isLoading: boolean;
  premiumAndDelta?: number;
  premiumAndDeltaPromo?: number;
  costPerPerson?: string;
}

export const InsuranceMitePriceCard = ({
  premiumAndDelta,
  premiumAndDeltaPromo,
  title,
  isLoading,
  costPerPerson,
}: Props) => {
  const { t } = useTranslation();

  return (
    <InsurancePriceCardContainer>
      <InsurancePriceCardTitle>{title}</InsurancePriceCardTitle>
      {!premiumAndDelta || isLoading ? (
        <Spinner />
      ) : (
        <>
          {premiumAndDeltaPromo ? (
            <>
              <Cost>
                {`${currencyRuLocaleWithoutFraction(
                  premiumAndDeltaPromo,
                  FRACTION_TWO
                )} ${UnicodeEnum.RUB}`}
              </Cost>
              <OldCost>
                {currencyRuLocaleWithoutFraction(premiumAndDelta, FRACTION_TWO)}{' '}
                {UnicodeEnum.RUB}
              </OldCost>
            </>
          ) : (
            <Cost>
              {currencyRuLocaleWithoutFraction(premiumAndDelta, FRACTION_TWO)}{' '}
              {UnicodeEnum.RUB}
            </Cost>
          )}
          {costPerPerson && (
            <CostPerPerson>
              {t('MITE_FORM:labels.costPerPerson', {
                cost: currencyRuLocaleWithoutFraction(
                  parseFloat(costPerPerson),
                  FRACTION_TWO
                ),
              })}
            </CostPerPerson>
          )}
        </>
      )}
    </InsurancePriceCardContainer>
  );
};

import type { InsuredPerson } from '@src/pages/mite';
import type { InsurancePrice, InsuranceProductPolicyType } from '@src/types';

export interface MiteStoreState {
  promoCode?: string;
  choosedPolicy?: string;
  insurancePrice?: InsurancePrice;
  insuranceProductPolicy?: InsuranceProductPolicyType;
  insuredPersons: InsuredPerson[];
  numberInsuredPersons: number;
  startDate: Date;
}

export const initMiteStoreState: MiteStoreState = {
  insuredPersons: [],
  numberInsuredPersons: 1,
  startDate: new Date(),
};

import { styled, typography } from '@pulse-web-ui/theme';

export const InsurancePriceCardContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 16px 24px;
  color: ${({ theme }) => theme.colors.text.primary};
  border-radius: ${({ theme }) => theme.common.radius12};
  background-color: ${({ theme }) => theme.colors.surface.background};
`;

export const InsurancePriceCardTitle = styled.div`
  ${typography.regular16};
  line-height: 24px;
  margin-bottom: 2px;
`;

export const OldCost = styled.span`
  ${typography.regular16}
  line-height: 24px;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-left: 8px;
`;

export const Cost = styled.span`
  ${typography.medium24}
`;

export const CostPerPerson = styled.div`
  ${typography.regular16}
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: 24px;
  margin-top: 2px;
`;
